import {
  SUBJECT_ALL,
  SUBJECT_GET,
  SUBJECT_DEL,
  SUBJECT_PUT,
  SUBJECT_POST,
  SUBJECT_LOADING,
  SUBJECT_ERROR,
  SUBJECT_RESET,
} from '../../actions/types';

const initialState = {
  subject: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case SUBJECT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SUBJECT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case SUBJECT_POST:
      return { isLoading: false, isCompleted: true };
    case SUBJECT_PUT:
      return { isLoading: false, isCompleted: true };
    case SUBJECT_DEL:
      return { isLoading: false, isCompleted: true };
    case SUBJECT_RESET:
      return { SUBJECT: null, isLoading: true, isCompleted: true };
    case SUBJECT_LOADING:
      return { isLoading: true, isCompleted: true };
    case SUBJECT_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}

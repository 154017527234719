import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';

import { Box, Button, Modal, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
// import expensesForm from 'components/Forms/expensesForm';
import ExpensesTable from 'components/Table/ExpensesTable';
import ExpensesApplyForm from 'components/Forms/ExpensesApplyForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function ExpensesList({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const expenses = useSelector((state) => state.expenses);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = useState(new Date());
  const [paidType, setPaidType] = useState('TRANSFER');

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(actions.expensesAll({}));
    return () => {};
  }, []);

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.expensesDelete(id));
        dispatch(actions.expensesAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    history.push(`/expenses/edit/${id}`);
  };

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        paidType,
        date,
      };
      await dispatch(actions.expensesCreate(dataSubmit));
      await dispatch(actions.expensesAll({}));
      reset();
      handleClose();
    }
  };

  const renderModal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            รายการจ่าย
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ExpensesApplyForm
              control={control}
              Controller={Controller}
              date={date}
              setDate={setDate}
              paidType={paidType}
              setPaidType={setPaidType}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );

  const renderTable = () => (
    <ExpensesTable
      expenses={expenses}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  );

  const renderAddButton = () => (
    <div className="flex justify-end py-1">
      <Button variant="contained" onClick={handleOpen}>
        เพิ่ม
      </Button>
    </div>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      {renderAddButton()}
      {renderTable()}
      {renderModal()}
    </div>
  );
}

ExpensesList.propTypes = {};

export default ExpensesList;

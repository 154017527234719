import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

import _ from 'lodash';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (rows) => {
  const productList = _.map(rows, (row, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: [
        { text: row?.customer?.prefix },
        { text: row?.customer?.firstname },
        { text: '\t' },
        { text: row?.customer?.lastname },
      ],

      alignment: 'center',
    },
    {
      text: row?.course?.name,
      alignment: 'center',
    },
    {
      text: row?.customer?.company,
      alignment: 'center',
    },
    {
      text: row?.customer?.tel,
      alignment: 'center',
    },
  ]);

  return productList;
};

const StudentApply = async (row) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');

  const std = _?.sortBy(row.rows, ['level.name']);

  const genItemBody = genBody(std);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 80, 20, 80],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'report ',
    },
    header: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text:
                  'ศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ ภาควิชาคอมพิวเตอร์ คณะวิศวกรรมศาสตร์',
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'รายชื่อผู้สนใจผลิตภัณฑ์ ',
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['8%', '28%', '26%', '18%', '20%'],
          body: [
            [
              {
                text: 'ลำดับ.',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ชื่อ-นามสกุล',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ผลิตภัณฑ์ที่สนใจ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'บริษัท',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'เบอร์โทร',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 10,
        bold: true,
        alignment: 'center',
      },
      header1: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
      header2: {
        alignment: 'right',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default StudentApply;

/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import { ViewTitle } from '../../components/ViewTitle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button } from '@mui/material';
import ApplyTable from '../../components/Table/ApplyTable';
import ApplyFilter from '../../components/Card/ApplyFilter';
import StudentApply from '../../components/PDF/StudentApply';

function ApplyList({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const apply = useSelector((state) => state.apply);
  const course = useSelector((state) => state.course);
  const level = useSelector((state) => state.level);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState('');
  const [courseSelect, setCourseSelect] = useState('');
  const [levelSelect, setLevelSelect] = useState('');

  useEffect(() => {
    dispatch(actions.courseAll({}));
    dispatch(actions.levelAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(
      actions.applyAll({
        name,
        page,
        size,
        courseSelect,
        levelSelect,
      }),
    );
    return () => {};
  }, [name, page, size, courseSelect]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderAddButton = () => (
    <div className="flex justify-between gap-2 ">
      <div className="w-full">{renderFilter()}</div>
      <div className="w-1/4 py-4 flex justify-end ">
        <div className="flex gap-2">
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={() => StudentApply(apply)}
          >
            พิมพ์
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => history.push('new')}
          >
            เพิ่ม
          </Button>
        </div>
      </div>
    </div>
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.applyDelete(id));
        dispatch(actions.applyAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    // history.push(`/subject/course/edit/${id}`);
  };

  const handleDetail = (id) => {
    history.push(`/apply/apply/detail/${id}`);
  };

  const renderFilter = () => (
    <div>
      <ApplyFilter
        setName={setName}
        course={course}
        courseSelect={courseSelect}
        setCourseSelect={setCourseSelect}
        level={level}
        levelSelect={levelSelect}
        setLevelSelect={setLevelSelect}
      />
    </div>
  );

  const renderTable = () => (
    <ApplyTable
      apply={apply}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleDetail={handleDetail}
    />
  );

  return (
    <div>
      {renderTitle()}
      {renderAddButton()}
      {renderTable()}
    </div>
  );
}

ApplyList.propTypes = {};

export default ApplyList;

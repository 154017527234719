import {
  REVENUE_ALL,
  REVENUE_GET,
  REVENUE_DEL,
  REVENUE_PUT,
  REVENUE_POST,
  REVENUE_LOADING,
  REVENUE_ERROR,
  REVENUE_RESET,
} from '../../actions/types';

const initialState = {
  revenue: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case REVENUE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case REVENUE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case REVENUE_POST:
      return { isLoading: false, isCompleted: true };
    case REVENUE_PUT:
      return { isLoading: false, isCompleted: true };
    case REVENUE_DEL:
      return { isLoading: false, isCompleted: true };
    case REVENUE_RESET:
      return { REVENUE: null, isLoading: true, isCompleted: true };
    case REVENUE_LOADING:
      return { isLoading: true, isCompleted: true };
    case REVENUE_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}

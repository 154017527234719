import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@mui/material';
import {
  BookOpen as BookOpenIcon,
  Code as CodeIcon,
  Activity as ActivityIcon,
  FileText as FileTextIcon,
  User as UserIcon,
  Droplet as DropletIcon,
  List as ListIcon,
  Paperclip as PaperclipIcon,
  DollarSign as DollarSignIcon,
} from 'react-feather';
import NavItem from './NavItem';
import { PassportAuth } from '../../contexts/AuthContext';
import { getStorage } from '../../utils/functions/localstorage';
// import accessRight from '../../utils/functions/accessRight';

const items = [
  {
    href: '/apply/apply',
    icon: PaperclipIcon,
    title: 'รายการผู้ที่สนใจ',
    name: 'CUSTOMER',
    sub: [
      {
        href: '/apply/apply',
        title: 'รายการใบสมัคร',
      },
      // {
      //   href: '/apply/list',
      //   title: 'รายชื่อผลิตภัณฑ์',
      // },
    ],
  },
  {
    href: '/subject/course',
    icon: ListIcon,
    title: 'ผลิตภัณฑ์',
    name: 'MANAGEMENT',
    sub: [
      // {
      //   href: '/subject/subject',
      //   title: 'วิชาเรียน',
      // },
      {
        href: '/subject/course',
        title: 'ผลิตภัณฑ์',
      },
      // {
      //   href: '/subject/level',
      //   title: 'ระดับชั้นเรียน',
      // },
    ],
  },
  // {
  //   href: '/expenses/list',
  //   icon: DollarSignIcon,
  //   title: 'บันทึกรายจ่าย',
  //   name: 'CUSTOMER',
  //   sub: [],
  // },
  {
    href: '/customer/customer',
    icon: UserIcon,
    title: 'รายชื่อผู้สนใจ',
    name: 'CUSTOMER',
    sub: [],
  },

  {
    href: '/monitor/dashboard',
    icon: ActivityIcon,
    title: 'แดชบอร์ด',
    name: 'MONITOR',
    sub: [],
  },
  {
    href: '/profile/profile',
    icon: UserIcon,
    title: 'โปรไฟล์',
    name: 'PROFILE',
    sub: [],
  },
  {
    href: '/management/employee',
    icon: FileTextIcon,
    title: 'จัดการระบบ',
    name: 'MANAGEMENT',
    sub: [
      {
        href: '/management/employee',
        title: 'จัดการพนักงาน',
      },
      {
        href: '/management/role-type',
        title: 'จัดการบทบาท',
      },
      {
        href: '/management/user',
        title: 'จัดการผู้ใช้งาน',
      },
    ],
  },
];

const DashboardSidebar = ({ me, onMobileClose, openMobile }) => {
  const {
    firstname = '',
    lastname = '',
    department: { name: departmentName = '' } = {},
  } = JSON.parse(getStorage('remember'));

  const user = {
    jobTitle: departmentName,
    name: `${firstname} ${lastname}`,
  };

  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box className="p-6">
        <div className="p-2 rounded-xl flex flex-wrap justify-center  bg-gray-50">
          <div className="lg:w-1/3 flex justify-center ">
            <div className="bg-gray-300 rounded-full p-1">
              <UserIcon size={40} color={'grey'} />
            </div>
          </div>
          <div className="w-full lg:w-2/3 text-center mt-3">
            <Typography color="textPrimary" variant="body2">
              {user.name}
            </Typography>
            {/* <Typography color="palevioletred" variant="body2">
              {user.jobTitle}
            </Typography> */}
          </div>
        </div>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            // if (accessRight(me, item.name)) {
            return (
              <div key={item.title}>
                <NavItem
                  href={item.href}
                  title={item.title}
                  icon={item.icon}
                  sub={item.sub}
                  me={me}
                  moduleName={item.name}
                />
              </div>
            );
            // }
            // return <></>;
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Button
          size="xs"
          startIcon={<i className="fas fa-sign-out-alt"></i>}
          variant="outlined"
          onClick={() => {
            onSignOut();
          }}
        >
          ออกจากระบบ
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              zIndex: 0,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  me: PropTypes.object,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { ExpensesList, EditExpenses } from '../views/Expenses';
import { HomeNavbar } from '../components/Nevbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';
import { PersonalProfile } from '../views/Profile';

export function Expenses() {
  const module = 'EXPENSES';
  const prefix = '/expenses';
  const name = 'รายจ่าย';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <div className="min-h-screen">
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />

      <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
        <div className="py-4">
          <Switch>
            <Route exact path={`${prefix}/list`}>
              <ExpensesList title="รายการรายจ่าย" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/edit/:id`}>
              <EditExpenses title="แก้ไขรายจ่าย" subtitle={name} />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Expenses;

import {
  SUBJECT_ALL,
  SUBJECT_GET,
  SUBJECT_DEL,
  SUBJECT_PUT,
  SUBJECT_POST,
  SUBJECT_LOADING,
  SUBJECT_ERROR,
  SUBJECT_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const subjectCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SUBJECT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/subject`,
      payload,
    );
    dispatch({ type: SUBJECT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBJECT_ERROR });
    throw new Error(error);
  }
};

export const subjectAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = 200, page = 1, course = '', level = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/subject?name=${name}&size=${size}&page=${page}&course=${course}&level=${level}`,
    );
    if (status === 200) {
      dispatch({ type: SUBJECT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBJECT_ERROR });
    throw new Error(error);
  }
};

export const subjectGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/subject/${id}`,
    );
    if (status === 200) {
      dispatch({ type: SUBJECT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBJECT_ERROR });
    throw new Error(error);
  }
};

export const subjectPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SUBJECT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/subject/${id}`,
      payload,
    );
    dispatch({ type: SUBJECT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBJECT_ERROR });
    throw new Error(error);
  }
};
export const subjectDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUBJECT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/subject/${id}`,
    );
    dispatch({ type: SUBJECT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBJECT_ERROR });
    throw new Error(error);
  }
};

export const subjectReset = () => async (dispatch) => {
  try {
    dispatch({ type: SUBJECT_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: SUBJECT_ERROR });
    throw new Error(error);
  }
};

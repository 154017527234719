export const PAIDTYPE_STATUS = {
  TRANSFER: {
    status_code: 'TRANSFER',
    description: 'โอนเงิน',
  },
  CASH: {
    status_code: 'CASH',
    description: 'เงินสด',
  },
};
export default PAIDTYPE_STATUS;

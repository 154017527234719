import React from 'react';

import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
  Switch,
  FormControlLabel,
} from '@mui/material';

export default function CourseTable({
  course,
  page,
  size,
  setPage,
  setSize,
  handleEdit,
  handleDelete,
  onOpen,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            {/* <colgroup>
                <col width="90%" />
                <col width="10%" />
              </colgroup> */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold  text-center "> ลำดับ</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center "> รหัสคอร์ส</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">ชื่อคอร์ส</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">สถานะ</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center "> ดำเนินการ</h1>
                </TableCell>
              </TableRow>
            </TableHead>

            {_.isEmpty(course?.rows) ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    ไม่มีรายการ
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {course?.rows?.map((e, index) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className=" text-center ">{index + 1}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.courseNumber}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="text-center">{e?.name}</h1>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={e?.status}
                            onChange={(each) =>
                              onOpen(each?.target.checked, e?.id)
                            }
                            name="รับสมัคร"
                          />
                        }
                        label="เปิดรับ"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex gap-1 justify-center  ">
                        <Button
                          onClick={() => handleEdit(e._id)}
                          color="warning"
                          variant="contained"
                        >
                          แก้ไข
                        </Button>
                        <Button
                          onClick={() => handleDelete(e._id)}
                          color="error"
                          size="small"
                          variant="contained"
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={course?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}

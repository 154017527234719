import { Autocomplete, Box, Button, Card, TextField } from '@mui/material';
import React from 'react';

export default function SubjectForm({
  control,
  Controller,
  course,
  employee,
  level,
  subject,
}) {
  return (
    <Card>
      <div className="p-2 lg:grid grid-cols-2">
        <div className="w-full px-1 py-1">
          <Controller
            name={'subjectId'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รหัสวิชา"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'name'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อวิชา"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'description'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รายละเอียด"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'price'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                label="ราคา"
                fullWidth
                size={'small'}
                required
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'level'}
            control={control}
            //   defaultValue={maintenanceTopics?.type}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                disableClearable
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={level.rows}
                autoHighlight
                getOptionLabel={(option) =>
                  `${option.levelId} | ${option.name}`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      '& > span': { mr: '10px' },
                    }}
                    {...props}
                  >
                    {`${option.levelId || ''} | ${option.name}`}
                  </Box>
                )}
                onChange={(e, value) => {
                  field.onChange(value._id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    size={'small'}
                    fullWidth
                    label="ระดับชั้น"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'course'}
            control={control}
            //   defaultValue={maintenanceTopics?.type}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                disableClearable
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={course.rows}
                autoHighlight
                getOptionLabel={(option) =>
                  `${option.courseNumber} | ${option.name}`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      '& > span': { mr: '10px' },
                    }}
                    {...props}
                  >
                    {`${option.courseNumber || ''} | ${option.name}`}
                  </Box>
                )}
                onChange={(e, value) => {
                  field.onChange(value._id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    size={'small'}
                    fullWidth
                    label="คอร์ส"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'teacher'}
            control={control}
            defaultValue={subject?.teacher}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                disableClearable
                name={field.name}
                ref={field.ref}
                defaultValue={field.value}
                options={employee.rows}
                autoHighlight
                getOptionLabel={(option) =>
                  `${option.firstname} ${option.lastname}`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      '& > span': { mr: '10px' },
                    }}
                    {...props}
                  >
                    {`${option.firstname} ${option.lastname}`}
                  </Box>
                )}
                onChange={(e, value) => {
                  field.onChange(value._id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    size={'small'}
                    fullWidth
                    label="ผู้สอน"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </div>
      </div>
      <div className="py-2 flex justify-center">
        <Button type="submit" variant="contained">
          บันทึก
        </Button>
      </div>
    </Card>
  );
}

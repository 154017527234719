import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

import _ from 'lodash';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (rows) => {
  const productList = _.map(rows, (row, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: row?.name,
    },
    {
      text: row?.price?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      alignment: 'right',
    },
  ]);

  return productList;
};

const Invoice = async (row, apply) => {
  // eslint-disable-next-line global-require
  const THBText = require('thai-baht-text');
  console.log('info', apply);
  console.log('row', row);
  const genItemBody = genBody(apply?.order);
  const documentRef = {
    pageSize: 'A5',
    pageOrientation: 'portait',
    pageMargins: [20, 150, 20, 80],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: 'receipt ',
    },
    header: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['100%'],
          body: [
            [
              {
                text: 'โรงเรียนกวดวิชา พี เอส ที',
                style: 'headerPage',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ที่อยู่ 309/2 ตำบล ตำนาน อำเภอ เมือง จังหวัด พัทลุง',
                style: 'header',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'โทร 085-5808430 088-7902489',
                style: 'header',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ใบเสร็จรับเงิน',
                style: 'header1',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [20, 0],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['60%', '40%'],
          body: [
            [
              {
                text: `ได้รับเงินจาก : ${apply?.customer?.prefix}${apply?.customer?.firstname} ${apply?.customer?.lastname}`,

                border: [false, false, false, false],
              },
              {
                text: `เลขที่ใบเสร็จ ${row?.prefix}${row?.runningNumber} `,

                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `คอร์สเรียน  ${apply?.course?.name} `,

                border: [false, false, false, false],
              },
              {
                text: `วันที่ : ${dayjs(row?.date).format('DD/MM/BBBB')}`,

                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['10%', '70%', '20%'],
          body: [
            [
              {
                text: 'ลำดับ.',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วิชาเรียน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวนเงิน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
            [
              {
                text: 'รวมทั้งหมด',

                alignment: 'right',
                colSpan: 2,
                border: [true, true, true, true],
              },
              {
                text: '',
              },
              {
                text: apply?.totalPrice
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,'),

                alignment: 'right',
                border: [true, true, true, true],
              },
            ],
            [
              {
                text: 'ส่วนลด',

                alignment: 'right',
                colSpan: 2,
                border: [true, true, true, true],
              },
              {
                text: '',
              },
              {
                text:
                  row?.discount
                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0',

                alignment: 'right',
                border: [true, true, true, true],
              },
            ],
            [
              {
                text: 'ยอดชำระ',

                alignment: 'right',
                colSpan: 2,
                border: [true, true, true, true],
              },
              {
                text: '',
              },
              {
                text: row?.price
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                fillColor: '#d2d4d2',
                alignment: 'right',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [
      {
        margin: [20, 0],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['33.33%', '33.33%', '33.33%'],
          body: [
            [
              {
                text: ' ...................................... ',
                margin: [0, 10, 0, 0],
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: ``,
                border: [true, true, true, false],
              },
              {
                text: ' ...................................... ',
                margin: [0, 10, 0, 0],
                alignment: 'center',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: `ผู้จ่ายเงิน `,
                alignment: 'center',

                border: [true, false, true, false],
              },
              {
                text: ` `,

                border: [true, false, true, false],
              },
              {
                text: `ผู้รับเงิน `,
                alignment: 'center',
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: `วันที่....................................... `,

                border: [true, false, true, true],
              },
              {
                text: ` `,

                border: [true, false, true, true],
              },
              {
                text: `วันที่....................................... `,

                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
    ],

    styles: {
      headerPage: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 10,
        bold: true,
        alignment: 'center',
      },
      header1: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
      header2: {
        alignment: 'right',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default Invoice;

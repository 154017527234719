import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as actions from '../../redux/actions';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { ViewTitle } from 'components/ViewTitle';
import ProjectForm from 'components/Forms/ProjectForm';
import Loading from 'components/Loading';

function EditProject({ title, subtitle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector((state) => state.project);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    dispatch(actions.projectGet(id));
    return () => {};
  }, []);
  const renderTitle = () => (
    <ViewTitle title={title} subtitle={subtitle} project={project} />
  );

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.projectPut(id, data));
      await dispatch(actions.projectAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProjectForm
          project={project}
          control={control}
          Controller={Controller}
        />
      </form>
    </div>
  );

  if (!project?.isLoading && project?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }
  return <Loading isLoading />;
}

EditProject.propTypes = {};

export default EditProject;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

//view

import {
  ApplyList,
  NewApply,
  ApplyDetail,
  StudentListWithSubject,
} from '../views/Apply';
//

import { HomeNavbar } from '../components/Nevbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export function Apply() {
  const module = 'APPLY';
  const prefix = '/apply';
  const name = 'ข้อมูลผู้สนใจ';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <div className="min-h-screen">
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />

      <div className="relative lg:ml-64  min-h-screen  pt-16 px-8 ">
        <div className="py-4">
          <Switch>
            <Route exact path={`/`}>
              <ApplyList title="รายการผู้สนใจ" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/apply`}>
              <ApplyList title="รายการผู้สนใจ" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/new`}>
              <NewApply title="สมัครเรียน" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/apply/detail/:id`}>
              <ApplyDetail title="รายละเอียดใบสมัคร" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/list`}>
              <StudentListWithSubject
                title="รายชื่อนักเรียนตามวิชา"
                subtitle={name}
              />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Apply;

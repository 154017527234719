import {
  APPLY_ALL,
  APPLY_GET,
  APPLY_DEL,
  APPLY_PUT,
  APPLY_POST,
  APPLY_LOADING,
  APPLY_ERROR,
  APPLY_RESET,
} from '../types';

import api from '../../../utils/functions/api';
import { flatMap } from 'lodash';

export const applyCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: APPLY_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/apply`,
      payload,
    );
    dispatch({ type: APPLY_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: APPLY_ERROR });
    throw new Error(error);
  }
};

export const applyOnlineCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: APPLY_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/apply/online`,
      payload,
    );
    dispatch({ type: APPLY_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: APPLY_ERROR });
    throw new Error(error);
  }
};

export const applyAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = 200,
      page = 1,
      levelSelect = '',
      courseSelect = '',
      subjectSelect = '',
      firstname = '',
      lastname = '',
      tel = '',
      check = false,
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/apply?name=${name}&size=${size}&page=${page}&levelSelect=${levelSelect}&courseSelect=${courseSelect}&firstname=${firstname}&lastname=${lastname}&tel=${tel}&check=${check}&subjectSelect=${subjectSelect}`,
    );
    if (status === 200) {
      dispatch({ type: APPLY_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: APPLY_ERROR });
    throw new Error(error);
  }
};

export const applyGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/apply/${id}`,
    );
    if (status === 200) {
      dispatch({ type: APPLY_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: APPLY_ERROR });
    throw new Error(error);
  }
};

export const applyPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: APPLY_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/apply/${id}`,
      payload,
    );
    dispatch({ type: APPLY_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: APPLY_ERROR });
    throw new Error(error);
  }
};
export const applyDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: APPLY_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/apply/${id}`,
    );
    dispatch({ type: APPLY_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: APPLY_ERROR });
    throw new Error(error);
  }
};

export const applyReset = () => async (dispatch) => {
  try {
    dispatch({ type: APPLY_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: APPLY_ERROR });
    throw new Error(error);
  }
};

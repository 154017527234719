import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@mui/material';
import CustomerForm from 'components/Forms/CustomerForm';
import { BackButton } from 'components/Button';
import _ from 'lodash';

function EditCustomer({ title, subtitle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const customer = useSelector((state) => state.customer);
  const [addedEmployeeImage, setAddedEmployeeImage] = useState([]);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(actions.customerGet(id));

    return () => {};
  }, []);

  useEffect(() => {
    setValue('prefix', customer?.prefix);
    setValue('firstname', customer?.firstname);
    setValue('lastname', customer?.lastname);
    setValue('nickname', customer?.nickname);
    setValue('tel', customer?.tel);
    return () => {};
  }, [customer]);
  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      if (!_.isEmpty(addedEmployeeImage)) {
        // eslint-disable-next-line no-param-reassign
        data.image = {
          image: addedEmployeeImage[0]?.data_url,
          imageType: 'profile',
          alt: '',
        };
      }

      await dispatch(actions.customerPut(id, data));
      await dispatch(actions.customerAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <div className="py-1">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomerForm
          employeeImage={addedEmployeeImage}
          setEmployeeImage={setAddedEmployeeImage}
          control={control}
          Controller={Controller}
        />
      </form>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}
      <BackButton />
      {renderForm()}
    </div>
  );
}

EditCustomer.propTypes = {};

export default EditCustomer;

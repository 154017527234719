import {
  APPLY_ALL,
  APPLY_GET,
  APPLY_DEL,
  APPLY_PUT,
  APPLY_POST,
  APPLY_LOADING,
  APPLY_ERROR,
  APPLY_RESET,
} from '../../actions/types';

const initialState = {
  apply: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case APPLY_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case APPLY_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case APPLY_POST:
      return { isLoading: false, isCompleted: true };
    case APPLY_PUT:
      return { isLoading: false, isCompleted: true };
    case APPLY_DEL:
      return { isLoading: false, isCompleted: true };
    case APPLY_RESET:
      return { APPLY: null, isLoading: true, isCompleted: true };
    case APPLY_LOADING:
      return { isLoading: true, isCompleted: true };
    case APPLY_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}

import {
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { PAIDTYPE_STATUS } from 'utils/constants';

export default function ApplyDetailCard({
  apply,
  handleOpen,
  handleDeleteRevenue,
  handleOpenEdit,
  subject,
  Invoice,
}) {
  return (
    <div>
      <div className="py-1">
        <Card>
          <div className="p-2 text-xl">
            <div className="font-bold">ข้อมูลผู้สนใจ</div>
            <div>
              ชื่อ-สกุล : {apply?.customer?.firstname}{' '}
              {apply?.customer?.lastname}
            </div>

            <div>เบอร์โทร : {apply?.customer?.tel}</div>
            <div>บริษัท : {apply?.customer?.company}</div>
          </div>
        </Card>
      </div>
      <div>
        <Card>
          <div className="p-2 text-xl">
            <div className=" py-4 font-bold flex justify-between">
              ข้อมูลผลิตภัณฑ์ที่สนใจ
            </div>
            <div>ผลิตภัณฑ์ที่สนใจ: {apply?.course?.name}</div>
            <div>
              <Table>
                <TableHead></TableHead>
                <TableBody>
                  {_.map(apply?.order, (e, index) => (
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{e?.name}</TableCell>
                      <TableCell>
                        {e?.price
                          ?.toFixed(2)
                          ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </Card>
      </div>
      <div className="py-1"></div>
    </div>
  );
}

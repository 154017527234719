import React from 'react';
import PropTypes from 'prop-types';

function Profile2() {
  return <div>Profile2</div>;
}

Profile2.propTypes = {};

export default Profile2;

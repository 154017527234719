import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function Navbar() {
  return (
    <nav
      className="top-0  py-2 absolute z-50 w-full flex flex-wrap items-center justify-between  navbar-expand-lg"
      style={{ background: '#0D08A9' }}
    >
      <div className="container px-4 py-2 mx-auto flex flex-wrap items-center justify-between ">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            className=" cursor-pointer text-white text-gray-600 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
            to="/student/apply"
          >
            <div className="flex">
              {/* <img src="" width="100" height="100"></img> */}
              <div className="text-md font-bold text-white ">
                Intelligent Automation Research Center
              </div>
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { ChevronDown, ChevronUp } from 'react-feather';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Collapse,
  Button,
  Card,
} from '@mui/material';

export default function Dashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const apply = useSelector((state) => state.apply);
  const revenue = useSelector((state) => state.revenue);
  const expenses = useSelector((state) => state.expenses);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(40);
  const [total, setTotal] = useState(undefined);

  const profit =
    _.sumBy(revenue?.rows, (e) => e?.price) -
    _.sumBy(expenses?.rows, (e) => e?.price);

  useEffect(() => {
    dispatch(actions.applyAll({}));
    dispatch(actions.revenueAll({}));
    dispatch(actions.expensesAll({}));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const totalCard = () => (
    <div>
      <div className="py-2">
        <Card>
          <div className="p-2 py-6">
            <div className="text-center text-2xl">ผลประกอบการ กำไร-ขาดทุน</div>
            <div
              className={`text-center text-3xl ${
                profit <= 0 ? 'text-red-600' : 'text-green-600'
              } `}
            >
              {profit?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </div>
            <div className="text-center">บาท</div>
          </div>
        </Card>
      </div>
      <div className=" lg:grid grid-cols-2 gap-2 ">
        <Card>
          <div className="p-2">
            <div>จำนวนใบสมัคร</div>
            <div className="text-center text-2xl">{apply?.total}</div>
            <div className="text-center">รายการ</div>
          </div>
        </Card>
        <Card>
          <div className="p-2">
            <div>ยอดเงินตามใบสมัคร</div>
            <div className="text-center text-2xl">
              {_.sumBy(apply?.rows, (e) => e?.totalPrice)
                ?.toFixed(2)
                ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </div>
            <div className="text-center">บาท</div>
          </div>
        </Card>
        <Card>
          <div className="p-2">
            <div>ค้างจ่าย</div>
            <div className="text-center text-2xl">
              {_.sumBy(apply?.rows, (e) => e?.overdue)
                ?.toFixed(2)
                ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </div>
            <div className="text-center">บาท</div>
          </div>
        </Card>
        <Card>
          <div className="p-2 bg-green-400 ">
            <div>รับเงินแล้ว</div>
            <div className="text-center text-2xl">
              {_.sumBy(revenue?.rows, (e) => e?.price)
                ?.toFixed(2)
                ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </div>
            <div className="text-center">บาท</div>
          </div>
        </Card>
        <Card>
          <div className="p-2">
            <div>ส่วนลดรวม</div>
            <div className="text-center text-2xl">
              {' '}
              {_.sumBy(revenue?.rows, (e) => e?.discount)
                ?.toFixed(2)
                ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </div>
            <div className="text-center">บาท</div>
          </div>
        </Card>
        <Card>
          <div className="p-2 bg-red-400 ">
            <div>รายจ่ายรวม</div>
            <div className="text-center text-2xl">
              {' '}
              {_.sumBy(expenses?.rows, (e) => e?.price)
                ?.toFixed(2)
                ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </div>
            <div className="text-center">บาท</div>
          </div>
        </Card>
      </div>
    </div>
  );

  if (!apply.isLoading && apply.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>{totalCard()}</div>
      </div>
    );
  }

  return <Loading isLoading />;
}

Dashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Dashboard.defaultProps = {
  title: '',
  subtitle: '',
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/actions';
import { ViewTitle } from 'components/ViewTitle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {
  AlertTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomerTable from 'components/Table/CustomerTable';
import { CSVUploadModal } from 'components/Modal';
import NameFillter from 'components/Card/NameFillter';

function CustomerList({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState('');
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    dispatch(actions.customerAll({ name }));

    return () => {};
  }, [name]);

  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  const renderAddButton = () => (
    <Button variant="contained" onClick={() => history.push('new')}>
      เพิ่ม
    </Button>
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.customerDelete(id));
        dispatch(actions.customerAll({}));
      } catch (error) {}
    }
  };

  const handleEdit = (id) => {
    history.push(`edit/${id}`);
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.customerCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการได้');
      // }
    }
  };

  const renderTable = () => (
    <CustomerTable
      customer={customer}
      page={page}
      size={size}
      setPage={setPage}
      setSize={setSize}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  );

  const renderCSVUploadModal = () => (
    <CSVUploadModal
      csvData={csvData}
      setCsvData={setCsvData}
      fileTemplateURL="/filetemplate/Customer.csv"
      handleAddFromFile={handleAddFromFile}
      handleClose={handleCSVClose}
      isOpen={csvUploadOpen}
      titleName="พนักงาน"
      // typeRows={department}
      anotherComponent={
        <div>
          {' '}
          <AlertTitle>
            โปรดแทนที่ข้อมูลใน <strong>ตำแหน่ง</strong> ด้วยรหัสดังต่อไปนี้
          </AlertTitle>
          <div className="border rounded-sm h-80 overflow-y-auto"></div>
        </div>
      }
    />
  );

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div className="flex gap-1">
        <Button variant="contained" onClick={handleCSVOpen} color="success">
          อัพโหลด
        </Button>
      </div>
    </div>
  );

  const renderFilter = () => <NameFillter setName={setName} />;

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderCSVUploadModal()}
      {renderTitle()}{' '}
      <div className="lg:flex w-full py-2 gap-2">
        <div className="lg:w-2/3">{renderFilter()}</div>
        <div className="flex lg:w-1/3 justify-end py-2 gap-2">
          <div>{renderAddButton()}</div>
          <div>{renderAddButtom()}</div>
        </div>
      </div>
      {renderTable()}
    </div>
  );
}

CustomerList.propTypes = {};

export default CustomerList;

import {
  REVENUE_ALL,
  REVENUE_GET,
  REVENUE_DEL,
  REVENUE_PUT,
  REVENUE_POST,
  REVENUE_LOADING,
  REVENUE_ERROR,
  REVENUE_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const revenueCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REVENUE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/revenue`,
      payload,
    );
    dispatch({ type: REVENUE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: REVENUE_ERROR });
    throw new Error(error);
  }
};

export const revenueAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = 200, page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/revenue?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: REVENUE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: REVENUE_ERROR });
    throw new Error(error);
  }
};

export const revenueGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/revenue/${id}`,
    );
    if (status === 200) {
      dispatch({ type: REVENUE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: REVENUE_ERROR });
    throw new Error(error);
  }
};

export const revenuePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: REVENUE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/revenue/${id}`,
      payload,
    );
    dispatch({ type: REVENUE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: REVENUE_ERROR });
    throw new Error(error);
  }
};
export const revenueDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: REVENUE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/revenue/${id}`,
    );
    dispatch({ type: REVENUE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: REVENUE_ERROR });
    throw new Error(error);
  }
};

export const revenueeset = () => async (dispatch) => {
  try {
    dispatch({ type: REVENUE_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: REVENUE_ERROR });
    throw new Error(error);
  }
};

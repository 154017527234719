import { Button, Card } from '@mui/material';
import React, { useState } from 'react';

export default function EditApplyForm({
  subject,
  subjectSelect,
  setSubjectSelect,
  onUpdate,
}) {
  const [reload, setReload] = useState(false);
  const checkDisable = (id) => {
    const checked = _.find(subjectSelect, (e) => e?._id === id);
    if (checked) {
      return true;
    }
    return false;
  };

  const selectSubject = (data) => {
    subjectSelect.push(data);
    setSubjectSelect(subjectSelect);
    setReload(!reload);
  };

  const deleteSubject = (id) => {
    const index = _.findIndex(subjectSelect, (e) => e?._id === id?._id);
    const NewArrayArray = [...subjectSelect];
    NewArrayArray.splice(index, 1);
    setSubjectSelect(NewArrayArray);
  };
  return (
    <div className="text-sm">
      {' '}
      {_.map(subject?.rows, (each, index) => (
        <div className="py-1">
          <Card>
            <div
              className={checkDisable(each?._id) ? `bg-red-200` : `bg-white`}
            >
              <div className="p-2">
                <div className="flex justify-between">
                  <div className="font-bold"> {each?.name}</div>
                  <div>
                    {!checkDisable(each?._id) && (
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => selectSubject(each)}
                        >
                          เลือก
                        </Button>
                      </div>
                    )}
                    {checkDisable(each?._id) && (
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={() => deleteSubject(each)}
                        >
                          ลบ
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div>รายละเอียด: {each?.description}</div>
                <div>ราคา: {each?.price}</div>
              </div>
            </div>
          </Card>
        </div>
      ))}
      <div className="flex justify-center py-2">
        <Button variant="contained" onClick={() => onUpdate()}>
          บันทึก
        </Button>
      </div>
    </div>
  );
}

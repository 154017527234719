import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as actions from '../../redux/actions';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';

import { Card } from '@mui/material';
import { BackButton } from 'components/Button';
import LevelForm from 'components/Forms/LevelForm';

function LevelEdit({ title, subtitle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const level = useSelector((state) => state.level);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(actions.levelGet(id));
    return () => {};
  }, []);

  //   useEffect(() => {
  //     setValue('name', level?.name);
  //     setValue('levelNumber', level?.levelNumber);

  //     return () => {};
  //   }, [level]);

  const renderTitle = () => (
    <ViewTitle title={title} subtitle={subtitle} level={level} />
  );

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.levelPut(id, data));
      await dispatch(actions.levelAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <Card>
      <div className="p-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <LevelForm level={level} control={control} Controller={Controller} />
        </form>
      </div>
    </Card>
  );

  if (!level?.isLoading && level?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        {renderForm()}
        *หากข้อมูลไม่แสดงรีเฟรช 1 ครั้ง
      </div>
    );
  }
  return <Loading isLoading />;
}

LevelEdit.propTypes = {};

export default LevelEdit;

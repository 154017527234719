import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import * as actions from '../../redux/actions';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import SubjectForm from 'components/Forms/SubjectForm';
import { useForm, Controller } from 'react-hook-form';
import { BackButton } from 'components/Button';

function NewSubject({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const level = useSelector((state) => state.level);
  const employee = useSelector((state) => state.employee);
  const course = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(actions.levelAll({}));
    dispatch(actions.employeeAll({}));
    dispatch(actions.courseAll({}));
    return () => {};
  }, []);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    // console.log('data', data);
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.subjectCreate(data));
      await dispatch(actions.subjectAll({}));
      history.goBack();
    }
  };

  const renderForm = () => (
    <div className="py-1">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubjectForm
          control={control}
          Controller={Controller}
          course={course}
          employee={employee}
          level={level}
        />
      </form>
    </div>
  );
  return (
    <div>
      {renderTitle()}
      <BackButton />
      {renderForm()}
    </div>
  );
}

NewSubject.propTypes = {};

export default NewSubject;

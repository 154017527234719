import { Button, TextField } from '@mui/material';
import React from 'react';

export default function LevelForm({ control, Controller, level }) {
  return (
    <div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'levelId'}
          control={control}
          defaultValue={level ? level.levelId : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสชั้น"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'name'}
          control={control}
          defaultValue={level ? level.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ระดับชั้น"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>
      <div className="flex justify-center">
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </div>
  );
}

import { combineReducers } from 'redux';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';
// feature
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';
import ProjectReducer from './features/ProjectReducer';
import CourseReducer from './features/CourseReducer';
import LevelReducer from './features/LevelReducer';
import SubjectReducer from './features/SubjectReducer';
import ApplyReducer from './features/ApplyReducer';
import CustomerReducer from './features/CustomerReducer';
import RevenueReducer from './features/RevenueReducer';
import ExpensesReducer from './features/ExpensesReducer';

const rootRuducer = combineReducers({
  me: MeReducers,
  user: UserReducers,
  roletype: RoleTypesReducer,
  employee: EmployeeReducer,
  project: ProjectReducer,
  course: CourseReducer,
  level: LevelReducer,
  subject: SubjectReducer,
  apply: ApplyReducer,
  customer: CustomerReducer,
  revenue: RevenueReducer,
  expenses: ExpensesReducer,
});
export default rootRuducer;

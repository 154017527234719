import {
  LEVEL_ALL,
  LEVEL_GET,
  LEVEL_DEL,
  LEVEL_PUT,
  LEVEL_POST,
  LEVEL_LOADING,
  LEVEL_ERROR,
  LEVEL_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const levelCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LEVEL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/level`,
      payload,
    );
    dispatch({ type: LEVEL_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
    throw new Error(error);
  }
};

export const levelAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = 200, page = 1, checkStatus = false } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/level?name=${name}&size=${size}&page=${page}&checkStatus=${checkStatus}`,
    );
    if (status === 200) {
      dispatch({ type: LEVEL_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
    throw new Error(error);
  }
};

export const levelGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/level/${id}`,
    );
    if (status === 200) {
      dispatch({ type: LEVEL_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
    throw new Error(error);
  }
};

export const levelPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: LEVEL_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/level/${id}`,
      payload,
    );
    dispatch({ type: LEVEL_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
    throw new Error(error);
  }
};
export const levelDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: LEVEL_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/level/${id}`,
    );
    dispatch({ type: LEVEL_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
    throw new Error(error);
  }
};

export const levelReset = () => async (dispatch) => {
  try {
    dispatch({ type: LEVEL_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: LEVEL_ERROR });
    throw new Error(error);
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import * as actions from '../../redux/actions';
import PropTypes from 'prop-types';
import { ViewTitle } from 'components/ViewTitle';
import { Card } from '@mui/material';
import ApplyForm from 'components/Forms/ApplyForm';
import { BackButton } from 'components/Button';
import _ from 'lodash';

function NewApply({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const course = useSelector((state) => state.course);
  const customer = useSelector((state) => state.customer);
  const level = useSelector((state) => state.level);
  const subject = useSelector((state) => state.subject);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'subjectArray',
  });

  const [courseSelect, setCourseSelect] = useState();
  const [levelSelect, setLevelSelect] = useState('');
  const [subjectSelect, setSubjectSelect] = useState([]);

  useEffect(() => {
    dispatch(actions.courseAll({ checkStatus: true }));
    dispatch(actions.customerAll({}));
    dispatch(actions.levelAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(actions.subjectAll({ course: courseSelect, level: levelSelect }));

    return () => {};
  }, [courseSelect, levelSelect]);

  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      const dataSubmit = {
        ...data,
        order: subjectSelect,
        totalPrice: _.sumBy(subjectSelect, 'price'),
      };
      await dispatch(actions.applyCreate(dataSubmit));
      await dispatch(actions.applyAll({}));
      history.goBack();
    }
  };

  console.log(subjectSelect);
  const renderForm = () => (
    <div className="py-2">
      <Card>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ApplyForm
              customer={customer}
              control={control}
              Controller={Controller}
              setValue={setValue}
              course={course}
              level={level}
              courseSelect={courseSelect}
              setCourseSelect={setCourseSelect}
              levelSelect={levelSelect}
              setLevelSelect={setLevelSelect}
              subject={subject}
              fields={fields}
              append={append}
              remove={remove}
              subjectSelect={subjectSelect}
              setSubjectSelect={setSubjectSelect}
            />
          </form>
        </div>
      </Card>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <BackButton />
      {renderForm()}{' '}
    </div>
  );
}

NewApply.propTypes = {};

export default NewApply;

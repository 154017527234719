import {
  COURSE_ALL,
  COURSE_GET,
  COURSE_DEL,
  COURSE_PUT,
  COURSE_POST,
  COURSE_LOADING,
  COURSE_ERROR,
  COURSE_RESET,
} from '../../actions/types';

const initialState = {
  course: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case COURSE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case COURSE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case COURSE_POST:
      return { isLoading: false, isCompleted: true };
    case COURSE_PUT:
      return { isLoading: false, isCompleted: true };
    case COURSE_DEL:
      return { isLoading: false, isCompleted: true };
    case COURSE_RESET:
      return { COURSE: null, isLoading: true, isCompleted: true };
    case COURSE_LOADING:
      return { isLoading: true, isCompleted: true };
    case COURSE_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}

export const ROLE_LEVEL = {
  LOW: {
    label: 'เจ้าหน้าที่',
    value: 0,
  },
  MEDIUM: {
    label: 'ผู้จัดการ',
    value: 1,
  },
  HIGH: {
    label: 'ผู้ควบคุม',
    value: 2,
  },
};
export default ROLE_LEVEL;

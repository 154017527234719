import React from 'react';

import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  Button,
  TablePagination,
  Avatar,
} from '@mui/material';

export default function CustomerTable({
  customer,
  page,
  size,
  setPage,
  setSize,
  handleEdit,
  handleDelete,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            {/* <colgroup>
                <col width="90%" />
                <col width="10%" />
              </colgroup> */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <h1 className="font-bold  text-center "> ลำดับ</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center "> รหัสนักเรียน</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">ชื่อ-สกุล</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">ชื่อเล่น</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold  text-center ">เบอร์โทร</h1>
                </TableCell>
                <TableCell>
                  <h1 className="font-bold text-center "> ดำเนินการ</h1>
                </TableCell>
              </TableRow>
            </TableHead>

            {_.isEmpty(customer?.rows) ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    ไม่มีรายการ
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {customer?.rows?.map((e, index) => (
                  <TableRow key={e._id}>
                    <TableCell>
                      <h1 className=" text-center ">{index + 1}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.customerId}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className="text-center flex">
                        <div className="py-2">
                          {e?.prefix} {e?.firstname} {e?.lastname}
                        </div>
                        <div className="px-2 py-1">
                          <Avatar alt={e?.firstname} src={e?.image?.url} />
                        </div>
                      </h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.nickname}</h1>
                    </TableCell>
                    <TableCell>
                      <h1 className=" text-center ">{e?.tel}</h1>
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex gap-1 justify-center  ">
                        <Button
                          onClick={() => handleEdit(e._id)}
                          color="warning"
                          variant="contained"
                        >
                          แก้ไข
                        </Button>
                        <Button
                          onClick={() => handleDelete(e._id)}
                          color="error"
                          size="small"
                          variant="contained"
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 100]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={customer?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}

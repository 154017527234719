// common type

export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

export const USER_ALL = 'USER_ALL';
export const USER_GET = 'USER_GET';
export const USER_POST = 'USER_POST';
export const USER_PUT = 'USER_PUT';
export const USER_DEL = 'USER_DEL';
export const USER_CREATE = 'USER_CREATE';
export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';

// feature type
export const ROLETYPES_ALL = 'ROLETYPES_ALL';
export const ROLETYPES_GET = 'ROLETYPES_GET';
export const ROLETYPES_POST = 'ROLETYPES_POST';
export const ROLETYPES_PUT = 'ROLETYPES_PUT';
export const ROLETYPES_DEL = 'ROLETYPES_DEL';
export const ROLETYPES_LOADING = 'ROLETYPES_LOADING';
export const ROLETYPES_ERROR = 'ROLETYPES_ERROR';

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';
export const EMPLOYEE_RESET = 'EMPLOYEE_RESET';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';

export const DROPLET_PROJECT_ALL = 'DROPLET_PROJECT_ALL';
export const DROPLET_PROJECT_GET = 'DROPLET_PROJECT_GET';
export const DROPLET_PROJECT_POST = 'DROPLET_PROJECT_POST';
export const DROPLET_PROJECT_PUT = 'DROPLET_PROJECT_PUT';
export const DROPLET_PROJECT_DEL = 'DROPLET_PROJECT_DEL';
export const DROPLET_PROJECT_ERROR = 'DROPLET_PROJECT_ERROR';
export const DROPLET_PROJECT_LOADING = 'DROPLET_PROJECT_LOADING';

export const DROPLET_ALL = 'DROPLET_ALL';
export const DROPLET_GET = 'DROPLET_GET';
export const DROPLET_POST = 'DROPLET_POST';
export const DROPLET_PUT = 'DROPLET_PUT';
export const DROPLET_DEL = 'DROPLET_DEL';
export const DROPLET_ERROR = 'DROPLET_ERROR';
export const DROPLET_LOADING = 'DROPLET_LOADING';

export const PROJECT_ALL = 'PROJECT_ALL';
export const PROJECT_GET = 'PROJECT_GET';
export const PROJECT_POST = 'PROJECT_POST';
export const PROJECT_PUT = 'PROJECT_PUT';
export const PROJECT_DEL = 'PROJECT_DEL';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const PROJECT_LOADING = 'PROJECT_LOADING';
export const PROJECT_RESET = 'PROJECT_RESET';

export const SYSTEM_ALL = 'SYSTEM_ALL';
export const SYSTEM_GET = 'SYSTEM_GET';
export const SYSTEM_POST = 'SYSTEM_POST';
export const SYSTEM_PUT = 'SYSTEM_PUT';
export const SYSTEM_DEL = 'SYSTEM_DEL';
export const SYSTEM_ERROR = 'SYSTEM_ERROR';
export const SYSTEM_LOADING = 'SYSTEM_LOADING';

export const COURSE_ALL = 'COURSE_ALL';
export const COURSE_GET = 'COURSE_GET';
export const COURSE_POST = 'COURSE_POST';
export const COURSE_PUT = 'COURSE_PUT';
export const COURSE_DEL = 'COURSE_DEL';
export const COURSE_ERROR = 'COURSE_ERROR';
export const COURSE_LOADING = 'COURSE_LOADING';
export const COURSE_RESET = 'COURSE_RESET';

export const LEVEL_ALL = 'LEVEL_ALL';
export const LEVEL_GET = 'LEVEL_GET';
export const LEVEL_POST = 'LEVEL_POST';
export const LEVEL_PUT = 'LEVEL_PUT';
export const LEVEL_DEL = 'LEVEL_DEL';
export const LEVEL_ERROR = 'LEVEL_ERROR';
export const LEVEL_LOADING = 'LEVEL_LOADING';
export const LEVEL_RESET = 'LEVEL_RESET';

export const SUBJECT_ALL = 'SUBJECT_ALL';
export const SUBJECT_GET = 'SUBJECT_GET';
export const SUBJECT_POST = 'SUBJECT_POST';
export const SUBJECT_PUT = 'SUBJECT_PUT';
export const SUBJECT_DEL = 'SUBJECT_DEL';
export const SUBJECT_ERROR = 'SUBJECT_ERROR';
export const SUBJECT_LOADING = 'SUBJECT_LOADING';
export const SUBJECT_RESET = 'SUBJECT_RESET';

export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DEL = 'CUSTOMER_DEL';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_RESET = 'CUSTOMER_RESET';

export const APPLY_ALL = 'APPLY_ALL';
export const APPLY_GET = 'APPLY_GET';
export const APPLY_POST = 'APPLY_POST';
export const APPLY_PUT = 'APPLY_PUT';
export const APPLY_DEL = 'APPLY_DEL';
export const APPLY_ERROR = 'APPLY_ERROR';
export const APPLY_LOADING = 'APPLY_LOADING';
export const APPLY_RESET = 'APPLY_RESET';

export const REVENUE_ALL = 'REVENUE_ALL';
export const REVENUE_GET = 'REVENUE_GET';
export const REVENUE_POST = 'REVENUE_POST';
export const REVENUE_PUT = 'REVENUE_PUT';
export const REVENUE_DEL = 'REVENUE_DEL';
export const REVENUE_ERROR = 'REVENUE_ERROR';
export const REVENUE_LOADING = 'REVENUE_LOADING';
export const REVENUE_RESET = 'REVENUE_RESET';

export const EXPENSES_ALL = 'EXPENSES_ALL';
export const EXPENSES_GET = 'EXPENSES_GET';
export const EXPENSES_POST = 'EXPENSES_POST';
export const EXPENSES_PUT = 'EXPENSES_PUT';
export const EXPENSES_DEL = 'EXPENSES_DEL';
export const EXPENSES_ERROR = 'EXPENSES_ERROR';
export const EXPENSES_LOADING = 'EXPENSES_LOADING';
export const EXPENSES_RESET = 'EXPENSES_RESET';

/* eslint-disable no-alert */
import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { PassportAuth } from '../../contexts/AuthContext';

export default function Login() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { handleSignin } = useContext(PassportAuth);
  const history = useHistory();
  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await handleSignin(data.username, data.password);
      history.push('/');
    } catch (error) {
      window.alert('ไม่สามารถเข้าสู่ระบบ');
    }
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-sm font-bold">
                    เข้าสู่ระบบ
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-2">
                  <TextField
                    name="username"
                    label="ชื่อผู้ใช้"
                    variant="outlined"
                    fullWidth
                    {...register('username', { required: true })}
                    helperText={errors.username && 'กรุณาใส่ข้อมูล'}
                  />
                </div>

                <div className="my-2">
                  <TextField
                    label="รหัสผ่าน"
                    variant="outlined"
                    type="password"
                    fullWidth
                    {...register('password', { required: true })}
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                </div>
                <div className="text-center mt-6">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    type="submit"
                  >
                    เข้าสู่ระบบ
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
